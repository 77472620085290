export default {
  install(Vue) {
    Vue.prototype.rules = (requerido, tipo) => {
      const rules = []
      if (requerido) {
        rules.push(v => !!v || v === 0 || 'Requerido')
      }
      switch (tipo) {
        case undefined: break
        // case 'categoria': rules.push(v => (v && v.length <= 15) || 'Máximo 15 caracteres'); break
        case 'RUT': rules.push(v => require('rut.js').validate(v) || 'RUT no válido'); break
        case 'telefono': rules.push(v => !v || v.length === 9 || 'Teléfono no válido (9 dígitos)'); break
        case 'url': rules.push(v => !/[^0-9a-z_]/g.test(v) || 'URL no válida'); break
        case 'correo': rules.push((v) => {
          if (!v) {
            return true
          }
          const arr = v.split('@')
          if (arr[1] !== undefined && (arr[1].includes('.-') || arr[1].includes('-.'))) {
            return 'Correo no válido'
          }
          return !v || /^[a-zA-Z0-9]{1}([.]?[a-zA-Z0-9-_][.]?)*[^.]@[a-zA-Z0-9]{1}([.]?[a-zA-Z0-9-][.]?)*[^._-]\.[a-zA-Z]{1}[a-zA-Z]+$/.test(v) || 'Correo no válido'
        })
          break
          case 'positivo': rules.push(v => v > 0 || 'Mayor a 0'); break
          case 'positivo6': rules.push(v => v > 0 && v <= 6 || 'Máximo 6 metros'); break
      }
      return rules
    },

    Vue.prototype.check_id = (uuid) => {
      return /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(uuid)
    },

    Vue.prototype.check_id_reserva = (str) => {
      // https://stackoverflow.com/a/29299786/1333724
      if (typeof str !== 'string' || str.length !== 18) {
          return false;
      }

      let upperCaseToBit = (char) => char.match(/[A-Z]/) ? '1' : '0';
      let binaryToSymbol = (digit) => digit <= 25 ? String.fromCharCode(digit + 65) : String.fromCharCode(digit - 26 + 48);

      let parts = [
          str.slice(0,5).split("").reverse().map(upperCaseToBit).join(""),
          str.slice(5,10).split("").reverse().map(upperCaseToBit).join(""),
          str.slice(10,15).split("").reverse().map(upperCaseToBit).join(""),
      ];

      let check = parts.map(str => binaryToSymbol(parseInt(str, 2))).join("");

      return check === str.slice(-3);
    }
  }
}
