<template lang="pug">
  v-card
    v-card-text.pb-1
      v-row.mt-6.white(justify="center" fill-height no-gutters)
        v-col.text-center
          v-icon(x-large color="red") mdi-alert-circle
      v-row.mb-6.white(justify="center" fill-height no-gutters)
        v-col.text-center
          div.text-md-h4.text-h6.secondary--text(v-if="anulada" class="text-h4") Transacción anulada
          div.text-md-h4.text-h6.secondary--text(v-else-if="timeout" class="text-h4") Tiempo agotado
          div.text-md-h4.text-h6.secondary--text(v-else class="text-h4") Transacción rechazada
      v-row.primary.white--text(justify="center")
        v-col.text-center(cols="12")
          v-row(justify="center")
            v-col(cols="auto")
              div.text-h5 Detalles de la transacción
        v-col.white--text.text-md-h6.text-body-1.font-weight-medium(cols="12" md="8")
          v-row(v-if="resultado.num_reserve" dense)
            v-col(cols="6")
              div Nº Reserva
            v-col(cols="6")
              div : {{ resultado.num_reserve }}
          v-row(v-if="resultado.code_reserve" dense)
            v-col(cols="6")
              div Nº Orden de compra
            v-col(cols="6")
              div : {{ resultado.code_reserve }}
          v-row(v-if="resultado.monto" dense)
            v-col(cols="6")
              div Monto
            v-col(cols="6")
              div : {{ resultado.monto | currency('$ ', 0, { thousandsSeparator: '.' }) }}
          v-row(v-if="resultado.auth_code" dense)
            v-col(cols="6")
              div Código de autorización
            v-col(cols="6")
              div : {{ resultado.auth_code }}
          v-row(v-if="resultado.fecha" dense)
            v-col(cols="6")
              div Fecha
            v-col(cols="6")
              div : {{ resultado.fecha }}
          v-row(v-if="resultado.hora" dense)
            v-col(cols="6")
              div Hora
            v-col(cols="6")
              div : {{ resultado.hora }}
          v-row(v-if="resultado.tipo_pago" dense)
            v-col(cols="6")
              div Tipo de pago
            v-col(cols="6")
              div : {{ tipo_pago(resultado.tipo_pago) }}
      v-row(justify="center")
        v-col.text-center
          div.primary--text.text-md-h6.text-body-1 Por favor intente nuevamente
      v-row(justify="center" no-gutters)
        v-col(cols="auto")
          v-btn.my-3.pa-6(color="secondary" shaped href="/") Volver al inicio
      v-row(justify="center")
        v-col(cols="auto")
          // v-btn.my-3.pa-6(color="secondary" shaped) Información sobre aduana
          div.text-h6.primary--text.my-3 INFÓRMATE SOBRE NUESTRAS NOVEDADES
      v-row.mx-n4(justify="center" dense)
        v-col(cols="6")
          v-card
            v-img.text-center.align-center.white--text(src="images/post1.jpg" height="200")
              v-overlay(absolute color="black" opacity="0.6" z-index="-1")
              v-card-text
                div.text-body-1.text-md-h6 Bus de acercamiento Pto. Cisnes - Coyhaique
                v-btn.mt-3(outlined color="white" href="https://drive.google.com/file/d/1C5RHrMzvYsEp0VuvvkeDynjaTUjXNpJs/view" target="_blank") Descargar
        v-col(cols="6")
          v-card
            v-img.text-center.align-center.white--text(src="images/post2.jpg" height="200")
              v-overlay(absolute color="black" opacity="0.6" z-index="-1")
              v-card-text
                div.text-body-1.text-md-h6 Acceso a estacionamiento y servicio en Quellón
                v-btn.mt-3(outlined color="white" href="https://drive.google.com/file/d/1TU1wsQ2KF6l2lzA5UrRMiHO682L_iS_a/view" target="_blank") Descargar
        v-col(cols="12")
          v-card
            v-img.text-center.align-center.white--text(src="images/post3.jpg" height="250")
              v-overlay(absolute color="black" opacity="0.6" z-index="-1")
              v-card-text
                div.text-h5.d-flex(style="justify-content: center") Conoce nuestra Red de Partners y Descubre la Patagonia con Austravel
                v-btn.mt-3(outlined color="white" href="https://austravel.cl/" target="_blank") Ver aquí

      v-row.mb-0.mt-6.secondary(justify="center")
        v-col.d-flex.py-8(cols="auto")
          a(target="_blank" href="https://www.facebook.com/navieraustral")
            v-img(src="images/facebook.png" max-width="40" contain)
          a(target="_blank" href="https://x.com/navieraustral")
            v-img.mx-6(src="images/x.png" max-width="40" contain)
          a(target="_blank" href="https://www.instagram.com/navieraustral/")
            v-img(src="images/instagram.png" max-width="40" contain)
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    correo: '',
    correoLoading: false,
    emailRules: [
      v => v.trim() === '' || /.+@.+\..+/.test(v) || 'Correo no válido',
    ],
    resultado: {},
    cuotas: false,
    exito: false,
    anulada: false,
    timeout: false
  }),

  mounted( ) {

    let b64 = this.$route.query.resultado
    if( !b64 ) {
      this.$router.push('/');
      return
    }
    let str
    try {
      str = atob(b64)
    } catch( err ) {
      this.$router.push('/');
      return
    }
    this.resultado = JSON.parse(str)
    this.resultado.auth_code = this.resultado.auth_code === '000000' ? undefined : this.resultado.auth_code
    this.cuotas = this.resultado.tipo_pago && this.resultado.tipo_pago !== 'VD' && this.resultado.tipo_pago !== 'VP'
    this.anulada = this.resultado.anulada
    this.timeout = this.resultado.timeout
  },

  methods: {

    enviarCorreo( ) {
      if( this.$refs.form.validate() && this.correo.trim() !== '' ) {
        this.correoLoading = true;
      }
    },

    tipo_pago( tipo ) {
      switch(tipo) {
        case 'VD': return 'Tarjeta de débito';
        case 'VN':
        case 'VC':
        case 'SI':
        case 'S2':
        case 'NC': return 'Tarjeta de cŕedito';
        case 'VP': return 'Tarjeta de prepago';
      }
      return 'Otro'
    },

    tipo_cuotas( tipo ) {
      switch(tipo) {
        case 'VD': return 'Tarjeta de débito';
        case 'VN': return 'Sin cuotas';
        case 'VC': return 'Cuotas normales';
        case 'SI':
        case 'S2':
        case 'NC': return 'Sin interés';
      }
      return 'Otros'
    }
  }
}
</script>

<style type="text/css">

  .pacifico {
    font-family: 'Pacifico', cursive !important;
    font-size: x-large;
  }

</style>