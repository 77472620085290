export default {
  comau: {
    title: 'AG Comau',
    src: 'assets/imgs/jacaf.jpg',
    settings: {
      rows: 12,
      cols: 21
    },
    model: [
      ['_', '_', '_', 1,  2,  3,  4,   5, '_', 58,  59,  60,  61,  62,  63,  '_', 122, 123, '_', 140, 141, '_'],
      ['_', '_', '_', 6,  7,  8,  9,  10, '_', 64,  65,  66,  67,  68,  69,  '_', 124, 125, '_', 142, 143, '_'],
      ['_', '_', '_', 11, 12, 13, 14,'_', '_', 70,  71,  72,  73,  74,  75,  '_', 126, 127, '_', 144, 145, '_'],
      ['_', '_', '_', 15, 16, 17, 18, 19, '_', 76,  77,  78,  79,  80,  81,  '_', 128, 129, '_', '_', '_', '_'],
      ['_', '_', '_', 20, 21, 22, 23, 24, '_', 82,  83,  84,  85,  86,  87,  '_', 130, 131, '_', '_', '_', '_'],
      ['_', '_', '_', 25, 26, 27, 28, 29, '_', 88,  89,  90,  91,  92,  93,  '_', 132, 133, '_', '_', '_', '_'],
      ['_', '_', '_', 30, 31, 32, 33,'_', '_', 94,  95,  96,  97,  98,  99,  '_', 134, 135, '_', '_', '_', '_'],
      ['_', '_', '_', 34, 35, 36, 37, 38, '_', 100, 101, 102, 103, 104, 105, '_', 136, 137, '_', '_', '_', '_'],
      [ 39,  40, '_', 41, 42, 43, 44, 45, '_', 106, 107, 108, 109, 110, 111, '_', 138, 139, '_', '_', '_', '_'],
      [ 46,  47, '_', 48, 49, 50, 51, 52, '_', 112, 113, 114, 115, 116, 117, '_', '_', '_', '_', '_', '_', '_'],
      ['_', '_', '_', 53, 54, 55, 56, 57, '_', '_', '_', 118, 119, '_', '_', '_', '_', '_', '_', '_', '_', '_'],
      ['_', '_', '_', '_', '_', '_', '_', '_', 120, 121, '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_']
    ]
  },
  jacaf: {
    title: 'AG Jacaf',
    src: 'assets/imgs/jacaf.jpg',
    settings: {
      rows: 21,
      cols: 19
    },
    model: [
      [ '_', '_',   1,   2,   3, '_',   4,   5,   6,   7,   8,   9, '_',  10,  11,  12, '_', '_', '_' ],
      [ '_', '_',  13,  14,  15, '_',  16,  17,  18,  19,  20,  21, '_',  22,  23,  24, '_', '_', '_' ],
      [ '_', '_',  25,  26,  27, '_',  28,  29,  30,  31,  32,  33, '_',  34,  35,  36, '_', '_', '_' ],
      [ '_', '_',  37,  38,  39, '_',  40,  41, '_', '_',  42,  43, '_',  44,  45,  46, '_', '_', '_' ],
      [ '_', '_',  47,  48,  49, '_',  50,  51,  52,  53,  54,  55, '_',  56,  57,  58, '_', '_', '_' ],
      [ '_', '_',  59,  60,  61, '_',  62,  63, '_', '_',  64,  65, '_',  66,  67,  68, '_', '_', '_' ],
      [ '_', '_',  69,  70,  71, '_',  72,  73,  74,  75,  76,  77, '_',  78,  79,  80, '_', '_', '_' ],
      [ '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_' ],
      [  81,  82,  83, '_',  84,  85,  86, '_',  87,  88,  89, '_',  90,  91,  92, '_',  93,  94,  95 ],
      [  96,  97,  98, '_',  99, 100, 101, '_', 102, '_', 103, '_', 104, 105, 106, '_', 107, 108, 109 ],
      [ 110, 111, 112, '_', 113, 114, 115, '_', 116, 117, 118, '_', 119, 120, 121, '_', 122, 123, 124 ],
      [ 125, 126, 127, '_', 128, 129, 130, '_', 131, '_', 132, '_', 133, 134, 135, '_', 136, 137, 138 ],
      [ 139, 140, 141, '_', 142, 143, 144, '_', 145, 146, 147, '_', 148, 149, 150, '_', 151, 152, 153 ],
      [ 154, 155, 156, '_', 157, 158, 159, '_', 160, 161, 162, '_', 163, 164, 165, '_', 166, 167, 168 ],
      [ 169, 170, 171, '_', 172, 173, 174, '_', 175, 176, 177, '_', 178, 179, 180, '_', 181, 182, 183 ],
      [ 184, 185, 186, '_', '_', 187, 188, '_', 189, 190, 191, '_', 192, 193, '_', '_', 194, 195, 196 ],
      [ 197, 198, 199, '_', 200, 201, 202, '_', 203, 204, 205, '_', 206, 207, 208, '_', 209, 210, 211 ],
      [ 212, 213, 214, '_', 215, 216, 217, '_', 218, 219, 220, '_', 221, 222, 223, '_', 224, 225, 226 ],
      [ 227, 228, 229, '_', 230, 231, 232, '_', 233, 234, 235, '_', 236, 237, 238, '_', 239, 240, 241 ],
      [ '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', 242, 243, 244, '_', 245, 246, 247 ],
      [ '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', 251, '_', '_', 252, '_', '_', 248, 249, 250 ],
    ]
  },
  klio: {
    title: 'KLIO',
    src: 'assets/imgs/jacaf.jpg',
    settings: {
      rows: 13,
      cols: 19
    },
    model: [
      [ '_', '_',   1,   2,   3, '_',   4,   5,   6,   7,   8,   9, '_',  10,  11,  12, '_', '_', '_' ],
      [ '_', '_',  13,  14,  15, '_',  16,  17,  18,  19,  20,  21, '_',  22,  23,  24, '_', '_', '_' ],
      [ '_', '_',  25,  26,  27, '_',  28,  29,  30,  31,  32,  33, '_',  34,  35,  36, '_', '_', '_' ],
      [ '_', '_',  37,  38,  39, '_',  40,  41, '_', '_',  42,  43, '_',  44,  45,  46, '_', '_', '_' ],
      [ '_', '_',  47,  48,  49, '_',  50,  51,  52,  53,  54,  55, '_',  56,  57,  58, '_', '_', '_' ],
      [ '_', '_',  59,  60,  61, '_',  62,  63, '_', '_',  64,  65, '_',  66,  67,  68, '_', '_', '_' ],
      [ '_', '_',  69,  70,  71, '_',  72,  73,  74,  75,  76,  77, '_',  78,  79,  80, '_', '_', '_' ],
      [ '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_' ],
      [  81,  82,  83, '_',  84,  85,  86, '_',  87,  88,  89, '_',  90,  91,  92, '_',  93,  94,  95 ],
      [  96,  97,  98, '_',  99, 100, 101, '_', 102, '_', 103, '_', 104, 105, 106, '_', 107, 108, 109 ],
      [ 110, 111, 112, '_', 113, 114, 115, '_', 116, 117, 118, '_', 119, 120, 121, '_', 122, 123, 124 ],
      [ 125, 126, 127, '_', 128, 129, 130, '_', 131, '_', 132, '_', 133, 134, 135, '_', 136, 137, 138 ],
      [ 139, 140, 141, '_', 142, 143, 144, '_', 145, 146, 147, '_', 148, 149, 150, '_', '_', '_', '_' ],
    ]
  },
  queulat: {
    title: 'AG Queulat',
    src: 'assets/imgs/queulat.jpg',
    settings: {
      rows: 23,
      cols: 23
    },
    model: [
      ['_', '_', '_', '_', '_', '_', '_',  46,  47,  48,  49, '_',  50,  51,  52,  53, '_', '_', '_', '_', '_', '_', '_'],
      ['_', '_', '_', '_', '_', '_',  54,  55,  56,  57,  58, '_',  59,  60,  61,  62,  63, '_', '_', '_', '_', 226, 227],
      [  1,   2,   3,   4,   5, '_',  64,  65,  66,  67,  68, '_',  69,  70,  71,  72,  73, '_', 228, 229, 230, 231, 232],
      [  6,   7,   8,   9,  10, '_',  74,  75,  76,  77,  78, '_',  79,  80,  81,  82,  83, '_', 233, 234, 235, 236, 237],
      [ 11,  12,  13,  14,  15, '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', 238, 239, 240, 241, 242],
      [ 16,  17,  18,  19,  20, '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', 243, 244, 245, 246, 247],
      [ 21,  22,  23,  24,  25, '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', 248, 249, 250, 251, 252],
      [ 26,  27,  28,  29,  30, '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', 253, 254, 255, 256, 257],
      [ 31,  32,  33,  34,  35, '_', '_',  84,  85,  86,  87, '_',  88,  89,  90,  91, '_', '_', 258, 259, 260, 261, 262],
      [ 36,  37,  38,  39,  40, '_',  92,  93,  94,  95,  96, '_',  97,  98,  99, 100, 101, '_', 263, 264, 265, 266, 267],
      [ 41,  42,  43,  44,  45, '_', 102, 103, 104, 105, 106, '_', 107, 108, 109, 110, 111, '_', 268, 269, 270, 271, 272],
      ['_', '_', '_', '_', '_', '_', 112, 113, 114, 115, 116, '_', 117, 118, 119, 120, 121, '_', 273, 274, 275, 276, 277],
      ['_', '_', '_', '_', '_', '_', '_', 122, 123, 124, 125, '_', 126, 127, 128, 129, '_', '_', 278, 279, 280, 281, 282],
      ['_', '_', '_', '_', '_', '_', 130, 131, 132, 133, 134, '_', 135, 136, 137, 138, 139, '_', 283, 284, 285, 286, 287],
      ['_', '_', '_', '_', '_', '_', 140, 141, 142, 143, 144, '_', 145, 146, 147, 148, 149, '_', 288, 289, 290, 291, 292],
      ['_', '_', '_', '_', '_', '_', 150, 151, 152, 153, 154, '_', 155, 156, 157, 158, 159, '_', '_', '_', '_', '_', '_'],
      ['_', '_', '_', '_', '_', '_', '_', 160, 161, 162, 163, '_', 164, 165, 166, 167, '_', '_', '_', '_', '_', '_', '_'],
      ['_', '_', '_', '_', '_', '_', 168, 169, 170, 171, 172, '_', 173, 174, 175, 176, 177, '_', '_', '_', '_', '_', '_'],
      ['_', '_', '_', '_', '_', '_', 178, 179, 180, 181, 182, '_', 183, 184, 185, 186, 187, '_', '_', '_', '_', '_', '_'],
      ['_', '_', '_', '_', '_', '_', '_', 188, 189, 190, 191, '_', 192, 193, 194, 195, '_', '_', '_', '_', '_', '_', '_'],
      ['_', '_', '_', '_', '_', '_', 196, 197, 198, 199, 200, '_', 201, 202, 203, 204, 205, '_', '_', '_', '_', '_', '_'],
      ['_', '_', '_', '_', '_', '_', 206, 207, 208, 209, 210, '_', 211, 212, 213, 214, 215, '_', '_', '_', '_', '_', '_'],
      ['_', '_', '_', '_', '_', '_', 216, 217, 218, 219, 220, '_', 221, 222, 223, 224, 225, '_', '_', '_', '_', '_', '_']
    ]
  },
  'la tehuelche': {
    title: 'La Tehuelche',
    src: 'assets/imgs/queulat.jpg',
    settings: {
      rows: 18,
      cols: 25
    },
    model: [
      [  1,   2, '_',  27,  28, '_',  49,  50, '_',  81,  82, '_', '_', '_', 109, 110, '_', 139, 140, '_', 175, 176, '_', 209, 210 ],
      [  3,   4, '_',  29,  30, '_',  51,  52, '_',  83,  84, '_', '_', '_', 111, 112, '_', 141, 142, '_', 177, 178, '_', 211, 212 ],
      [  5,   6, '_',  31,  32, '_',  53,  54, '_',  85,  86, '_', '_', '_', 113, 114, '_', 143, 144, '_', 179, 180, '_', 213, 214 ],
      [  7,   8, '_',  33,  34, '_',  55,  56, '_',  87,  88, '_', '_', '_', 115, 116, '_', 145, 146, '_', 181, 182, '_', 215, 216 ],
      [  9,  10, '_',  35,  36, '_',  57,  58, '_',  89,  90, '_', '_', '_', 117, 118, '_', 147, 148, '_', 183, 184, '_', 217, 218 ],
      [ 11,  12, '_',  37,  38, '_',  59,  60, '_',  91,  92, '_', '_', '_', 119, 120, '_', 149, 150, '_', 185, 186, '_', 219, 220 ],
      [ 13,  14, '_',  39,  40, '_',  61,  62, '_',  93,  94, '_', '_', '_', 121, 122, '_', 151, 152, '_', 187, 188, '_', 221, 222 ],
      [ 15,  16, '_',  41,  42, '_',  63,  64, '_',  95,  96, '_', '_', '_', 123, 124, '_', 153, 154, '_', 189, 190, '_', 223, 224 ],
      [ 17,  18, '_',  43,  44, '_',  65,  66, '_',  97,  98, '_', '_', '_', 125, 126, '_', 155, 156, '_', 191, 192, '_', 225, 226 ],
      [ 19,  20, '_',  45,  46, '_',  67,  68, '_',  99, 100, '_', '_', '_', 127, 128, '_', 157, 158, '_', 193, 194, '_', 227, 228 ],
      [ 21,  22, '_',  47,  48, '_',  69,  70, '_', 101, 102, '_', '_', '_', 129, 130, '_', 159, 160, '_', 195, 196, '_', 229, 230 ],
      [ 23,  24, '_', '_', '_', '_',  71,  72, '_', 103, 104, '_', '_', '_', 131, 132, '_', 161, 162, '_', 197, 198, '_', 231, 232 ],
      [ 25,  26, '_', '_', '_', '_',  73,  74, '_', 105, 106, '_', '_', '_', 133, 134, '_', 163, 164, '_', 199, 200, '_', 233, 234 ],
      ['_', '_', '_', '_', '_', '_',  75,  76, '_', 107, 108, '_', '_', '_', 135, 136, '_', 165, 166, '_', 201, 202, '_', 235, 236 ],
      ['_', '_', '_', '_', '_', '_',  77,  78, '_', '_', '_', '_', '_', '_', 137, 138, '_', 167, 168, '_', 203, 204, '_', 237, 238 ],
      ['_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', 169, 170, '_', 205, 206, '_', 239, 240 ],
      ['_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', 171, 172, '_', 207, 208, '_', 241, 242 ],
      ['_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', 173, 174, '_', '_', '_', '_', 243, 244 ],
    ]
  },
  'agios': {
    title: 'Agios',
    src: 'assets/imgs/queulat.jpg',
    settings: {
      rows: 21,
      cols: 27
    },
    model: [
      [  1,  2,  3,  4, '_',  85,  86,  87,  88, '_', 143, 144, 145, '_', 173, 174, 175, '_', 203, 204, 205, 206, '_', '_', '_', '_', '_' ],
      [  5,  6,  7,  8, '_',  89,  90,  91,  92, '_', 146, 147, 148, '_', 176, 177, 178, '_', 207, 208, 209, 210, '_', '_', '_', '_', '_' ],
      [  9, 10, 11, 12, '_',  93,  94,  95,  96, '_', 149, 150, 151, '_', 179, 180, 181, '_', 211, 212, 213, 214, '_', '_', '_', '_', '_' ],
      [ 13, 14, 15, 16, '_',  97,  98,  99, 100, '_', 152, 153, 154, '_', 182, 183, 184, '_', 215, 216, 217, 218, '_', '_', '_', '_', '_' ],
      [ 17, 18, 19, 20, '_', '_', 101, 102, 103, '_', 155, 156, 157, '_', 185, 186, 187, '_', 219, 220, 221, '_', '_', '_', '_', '_', '_' ],
      [ 21, 22, 23, 24, '_', 104, 105, 106, 107, '_', 158, 159, 160, '_', 188, 189, 190, '_', 222, 223, 224, 225, '_', '_', '_', '_', '_' ],
      [ 25, 26, 27, 28, '_', 108, 109, 110, 111, '_', 161, 162, 163, '_', 191, 192, 193, '_', 226, 227, 228, 229, '_', '_', '_', '_', '_' ],
      [ 29, 30, 31, 32, '_', 112, 113, 114, 115, '_', 164, 165, 166, '_', 194, 195, 196, '_', 230, 231, 232, 233, '_', '_', '_', '_', '_' ],
      [ 33, 34, 35, 36, '_', 116, 117, 118, 119, '_', 167, 168, 169, '_', 197, 198, 199, '_', 234, 235, 236, 237, '_', 261, 262, 263, 264 ],
      [ 37, 38, 39, 40, '_', 120, 121, 122, 123, '_', 170, 171, 172, '_', 200, 201, 202, '_', 238, 239, 240, 241, '_', 265, 266, 267, 268 ],
      [ 41, 42, 43, 44, '_', '_', 124, 125, 126, '_', '_', '_', '_', '_', '_', '_', '_', '_', 242, 243, 244, '_', '_', 269, 270, 271, 272 ],
      [ 45, 46, 47, 48, '_', 127, 128, 129, 130, '_', '_', '_', '_', '_', '_', '_', '_', '_', 245, 246, 247, 248, '_', 273, 274, 275, 276 ],
      [ 49, 50, 51, 52, '_', 131, 132, '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', 249, 250, '_', 277, 278, 279, 280 ],
      [ 53, 54, 55, 56, '_', 133, 134, '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', 251, 252, '_', 281, 282, 283, 284 ],
      [ 57, 58, 59, 60, '_', 135, 136, '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', 253, 254, '_', 285, 286, 287, 288 ],
      [ 61, 62, 63, 64, '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', 289, 290, 291, 292 ],
      [ 65, 66, 67, 68, '_', 137, 138, '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', 255, 256, '_', 293, 294, 295, 296 ],
      [ 69, 70, 71, 72, '_', 139, 140, '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', 257, 258, '_', 297, 298, 299, 300 ],
      [ 73, 74, 75, 76, '_', 141, 142, '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', 259, 260, '_', '_', '_', '_', '_' ],
      [ 77, 78, 79, 80, '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_' ],
      [ 81, 82, 83, 84, '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_', '_' ],
    ],
    preferenciales: [135, 136, 137, 138, 255, 256]
  },
  kotaix: {
    title: 'Kotaix',
    src: 'assets/imgs/kotaix.jpg',
    settings: {
      rows: 28,
      cols: 11
    },
    model: [
      [   1,   2,   3,   4,   5, '_',   6,   7,   8,   9,  10 ],
      [  11,  12,  13,  14,  15, '_',  16,  17,  18,  19,  20 ],
      [  21,  22,  23,  24,  25, '_',  26,  27,  28,  29,  30 ],
      [  31,  32,  33,  34,  35, '_',  36,  37,  38,  39,  40 ],
      [  41,  42,  43,  44,  45, '_',  46,  47,  48,  49,  50 ],
      [  51,  52,  53,  54,  55, '_',  56,  57,  58,  59,  60 ],
      [  61,  62,  63,  64,  65, '_',  66,  67,  68,  69,  70 ],
      [  71,  72,  73,  74,  75, '_',  76,  77,  78,  79,  80 ],
      [ '_',  81,  82,  83,  84, '_',  85,  86,  87,  88,  89 ],
      [ '_',  90,  91,  92,  93, '_',  94,  95,  96,  97,  98 ],
      [ '_',  99, 100, 101, 102, '_', 103, 104, 105, 106, 107 ],
      [ '_', 108, 109, 110, 111, '_', 112, 113, 114, 115, 116 ],
      [ '_', 117, 118, 119, 120, '_', 121, 122, 123, 124, 125 ],
      [ '_', 126, 127, 128, 129, '_', 130, 131, 132, 133, 134 ],
      [ '_', 135, 136, 137, 138, '_', 139, 140, 141, 142, 143 ],
      [ '_', 144, 145, 146, 147, '_', 148, 149, 150, 151, 152 ],
      [ '_', 153, 154, 155, 156, '_', 157, 158, 159, 160, 161 ],
      [ '_', 162, 163, 164, 165, '_', 166, 167, 168, 169, 170 ],
      [ '_', 171, 172, 173, 174, '_', 175, 176, 177, 178, 179 ],
      [ '_', 180, 181, 182, 183, '_', 184, 185, 186, 187, 188 ],
      [ 189, 190, 191, 192, 193, '_', 194, 195, 196, 197, 198 ],
      [ 199, 200, 201, 202, 203, '_', 204, 205, 206, 207, 208 ],
      [ 209, 210, 211, 212, 213, '_', 214, 215, 216, 217, 218 ],
      [ 219, 220, 221, 222, 223, '_', 224, 225, 226, 227, 228 ],
      [ 229, 230, 231, 232, 233, '_', 234, 235, 236, 237, 238 ],
      [ 239, 240, 241, 242, 243, '_', 244, 245, 246, 247, 248 ],
      [ 249, 250, 251, 252, 253, '_', 254, 255, 256, 257, 258 ],
      [ 259, 260, 261, 262, 263, '_', 264, 265, 266, 267, 268 ]
    ]
  },
  fiura: {
    title: 'FIURA',
    src: 'assets/imgs/fiura.jpg',
    settings: {
      rows: 12,
      cols: 11
    },
    model: [
      [   1,   2,   3,   4,   5, '_',   6,   7,   8,   9,  10 ],
      [  11,  12,  13,  14,  15, '_',  16,  17,  18,  19,  20 ],
      [  21,  22,  23,  24,  25, '_',  26,  27,  28,  29,  30 ],
      [  31,  32,  33,  34,  35, '_',  36,  37,  38,  39,  40 ],
      [  41,  42,  43,  44,  45, '_',  46,  47,  48,  49,  50 ],
      [  51,  52,  53,  54,  55, '_',  56,  57,  58,  59,  60 ],
      [  61,  62,  63,  64,  65, '_',  66,  67,  68,  69,  70 ],
      [  71,  72,  73,  74,  75, '_',  76,  77,  78,  79,  80 ],
      [  81,  82,  83,  84,  85, '_',  86,  87,  88,  89,  90 ],
      [  91,  92,  93,  94,  95, '_',  96,  97,  98,  99, 100 ],
      [ 101, 102, 103, 104, 105, '_', 106, 107, 108, 109, 110 ],
      [ 111, 112, 113, 114, 115, '_', 116, 117, 118, 119, 120 ]
    ]
  },
  'josefina leonor': {
    title: 'Josefina Leonor',
    src: 'assets/imgs/fiura.jpg',
    settings: {
      rows: 5,
      cols: 11
    },
    model: [
      [   1,   2,   3,   4,   5, '_',   6,   7,   8,   9,  10 ],
      [  11,  12,  13,  14,  15, '_',  16,  17,  18,  19,  20 ],
      [  21,  22,  23,  24,  25, '_',  26,  27,  28,  29,  30 ],
      [  31,  32,  33,  34,  35, '_',  36,  37,  38,  39,  40 ],
      [  41,  42,  43,  44,  45, '_',  46,  47,  48,  49,  50 ]
    ]
  },
  caupolican: {
    title: 'Caupolican',
    src: 'assets/imgs/fiura.jpg',
    settings: {
      rows: 4,
      cols: 11
    },
    model: [
      [   1,   2,   3,   4,   5, '_',   6,   7,   8,   9,  10 ],
      [  11,  12,  13,  14,  15, '_',  16,  17,  18,  19,  20 ],
      [  21,  22,  23,  24,  25, '_',  26,  27,  28,  29,  30 ],
      [  31,  32,  33,  34,  35, '_',  36,  37,  38,  39,  40 ]
    ]
  },
  'don felipe': {
    title: 'Don Felipe',
    src: 'assets/imgs/fiura.jpg',
    settings: {
      rows: 2,
      cols: 11
    },
    model: [
      [   1,   2,   3,   4,   5, '_',   6,   7,   8,   9,  10 ],
      [  11,  12,  13,  14,  15, '_',  16,  17,  18,  19,  20 ]
    ]
  },
  'leptepu': {
    title: 'LEPTEPU',
    src: 'assets/imgs/fiura.jpg',
    settings: {
      rows: 11,
      cols: 11
    },
    model: [
      [   1,   2,   3,   4,   5, '_',   6,   7,   8,   9,  10 ],
      [  11,  12,  13,  14,  15, '_',  16,  17,  18,  19,  20 ],
      [  21,  22,  23,  24,  25, '_',  26,  27,  28,  29,  30 ],
      [  31,  32,  33,  34,  35, '_',  36,  37,  38,  39,  40 ],
      [  41,  42,  43,  44,  45, '_',  46,  47,  48,  49,  50 ],
      [  51,  52,  53,  54,  55, '_',  56,  57,  58,  59,  60 ],
      [  61,  62,  63,  64,  65, '_',  66,  67,  68,  69,  70 ],
      [  71,  72,  73,  74,  75, '_',  76,  77,  78,  79,  80 ],
      [ '_',  81,  82,  83,  84, '_',  85,  86,  87,  88,  89 ],
      [ '_',  90,  91,  92,  93, '_',  94,  95,  96,  97,  98 ],
      [ '_',  99, 100, '_', '_', '_', '_', '_', '_', '_', '_' ]
    ]
  }
}